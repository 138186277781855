/*
 * Upstream file for reference:
 * - Dark: https://github.com/element-hq/compound-design-tokens/blob/main/assets/web/css/cpd-theme-dark-base.css
 * - Light: https://github.com/element-hq/compound-design-tokens/blob/main/assets/web/css/cpd-theme-light-base.css
 */

.cpd-theme-dark.cpd-theme-dark {
  --cpd-color-text-primary: rgba(255, 255, 255, 0.9);
  --cpd-color-text-secondary: rgba(255, 255, 255, 0.65);
  /* TODO?
  --cpd-color-alpha-green-1400: hsla(152, 88%, 94%, 0.96);
  --cpd-color-alpha-green-1300: hsla(152, 93%, 88%, 0.91);
  --cpd-color-alpha-green-1200: hsla(156, 97%, 76%, 0.83);
  --cpd-color-alpha-green-1100: hsla(162, 98%, 57%, 0.74);
  --cpd-color-alpha-green-1000: hsla(163, 99%, 55%, 0.65);
  --cpd-color-alpha-green-900: hsla(164, 98%, 53%, 0.58);
  --cpd-color-alpha-green-800: hsl(168, 100%, 24%, 1);
  --cpd-color-alpha-green-700: hsl(165, 100%, 18%, 1);
  --cpd-color-alpha-green-600: hsl(162, 100%, 14%, 1);
  --cpd-color-alpha-green-500: hsl(160, 100%, 12%, 1);
  --cpd-color-alpha-green-400: hsl(155, 100%, 9%, 1);
  --cpd-color-alpha-green-300: hsl(151, 100%, 7%, 1);
  --cpd-color-alpha-green-200: hsl(147, 100%, 6%, 1);
  --cpd-color-alpha-green-100: hsl(144, 100%, 6%, 1);

  --cpd-color-alpha-gray-1400: hsla(214, 78%, 98%, 0.95);
  --cpd-color-alpha-gray-1300: hsla(213, 73%, 97%, 0.89);
  --cpd-color-alpha-gray-1200: hsla(215, 74%, 96%, 0.79);
  --cpd-color-alpha-gray-1100: hsla(215, 92%, 95%, 0.68);
  --cpd-color-alpha-gray-1000: hsla(213, 93%, 94%, 0.61);
  --cpd-color-alpha-gray-900: hsla(211, 94%, 94%, 0.54);
  --cpd-color-alpha-gray-800: hsla(216, 100%, 94%, 0.41);
  --cpd-color-alpha-gray-700: hsla(213, 85%, 95%, 0.27);
  --cpd-color-alpha-gray-600: hsla(225, 46%, 95%, 0.2);
  --cpd-color-alpha-gray-500: hsla(214, 41%, 97%, 0.15);
  --cpd-color-alpha-gray-400: hsla(270, 37%, 93%, 0.1);
  --cpd-color-alpha-gray-200: hsla(286, 31%, 82%, 0.04);
  --cpd-color-alpha-gray-100: hsla(214, 10%, 86%, 0.02);
  */
  /* Hover of room list items, search bar bg, space icon bg */
  --cpd-color-alpha-gray-300: rgba(255, 255, 255, 0.06);

  /* TODO more accent variants? */
  --cpd-color-green-1400: #F1F8E9;
  --cpd-color-green-1300: #DCEDC8;
  --cpd-color-green-1200: #C5E1A5;
  --cpd-color-green-1100: #AED581;
  --cpd-color-green-1000: #9CCC65;
  --cpd-color-green-900: #8BC34A;
  --cpd-color-green-800: #8BC34A;
  --cpd-color-green-700: #8BC34A;
  --cpd-color-green-600: #8BC34A;
  --cpd-color-green-500: #8BC34A;
  --cpd-color-green-400: #689F38;
  --cpd-color-green-300: #33691E;
  --cpd-color-green-200: #33691E;
  --cpd-color-green-100: #33691E;

  /* TODO
  --cpd-color-red-1400: #ffe9e6;
  --cpd-color-red-1300: #ffd4cd;
  --cpd-color-red-1200: #ffaea4;
  --cpd-color-red-1100: #ff877c;
  --cpd-color-red-1000: #ff665d;
  --cpd-color-red-900: #fd3e3c;
  --cpd-color-red-800: #d1212a;
  --cpd-color-red-700: #9f0d1e;
  --cpd-color-red-600: #830009;
  --cpd-color-red-500: #710000;
  --cpd-color-red-400: #590000;
  --cpd-color-red-300: #470000;
  --cpd-color-red-200: #3e0000;
  --cpd-color-red-100: #370000;
  */

  --cpd-color-gray-1400: #eeeeee;
  --cpd-color-gray-1300: #e0e0e0;
  --cpd-color-gray-1200: #b3b3b3;
  --cpd-color-gray-1100: #aaaaaa;
  --cpd-color-gray-1000: #9e9e9e;
  --cpd-color-gray-900: #808080;
  --cpd-color-gray-800: #757575;
  --cpd-color-gray-700: #424242;
  --cpd-color-gray-600: #424242;
  --cpd-color-gray-500: #424242;
  --cpd-color-gray-400: #303030;
  --cpd-color-gray-300: #212121;
  --cpd-color-gray-200: #212121;
  --cpd-color-gray-100: #171717;
  --cpd-color-theme-bg: #171717;
  --cpd-color-tooltip-bg: #424242;
}

.cpd-theme-light.cpd-theme-light {
  /* TODO?
  --cpd-color-alpha-gray-1400: hsla(223, 64%, 2%, 0.9);
  --cpd-color-alpha-gray-1300: hsla(225, 57%, 3%, 0.84);
  --cpd-color-alpha-gray-1200: hsla(213, 73%, 3%, 0.77);
  --cpd-color-alpha-gray-1100: hsla(215, 74%, 5%, 0.71);
  --cpd-color-alpha-gray-1000: hsla(218, 79%, 6%, 0.66);
  --cpd-color-alpha-gray-900: hsla(213, 83%, 7%, 0.61);
  --cpd-color-alpha-gray-800: hsla(213, 87%, 9%, 0.5);
  --cpd-color-alpha-gray-700: hsla(215, 96%, 10%, 0.35);
  --cpd-color-alpha-gray-600: hsla(212, 97%, 12%, 0.26);
  --cpd-color-alpha-gray-500: hsla(212, 87%, 15%, 0.2);
  --cpd-color-alpha-gray-400: hsla(213, 90%, 20%, 0.12);
  --cpd-color-alpha-gray-300: hsla(216, 89%, 18%, 0.06);
  --cpd-color-alpha-gray-200: hsla(200, 41%, 36%, 0.04);
  --cpd-color-alpha-gray-100: hsla(210, 48%, 41%, 0.02);

  --cpd-color-alpha-green-1400: hsl(149, 100%, 7%, 1);
  --cpd-color-alpha-green-1300: hsl(157, 100%, 10%, 1);
  --cpd-color-alpha-green-1200: hsl(162, 100%, 14%, 1);
  --cpd-color-alpha-green-1100: hsl(165, 100%, 18%, 1);
  --cpd-color-alpha-green-1000: hsl(166, 100%, 21%, 1);
  --cpd-color-alpha-green-900: hsl(168, 100%, 24%, 1);
  --cpd-color-alpha-green-800: hsl(166, 100%, 30%, 1);
  --cpd-color-alpha-green-700: hsla(163, 99%, 38%, 0.96);
  --cpd-color-alpha-green-600: hsla(156, 99%, 36%, 0.56);
  --cpd-color-alpha-green-500: hsla(154, 96%, 37%, 0.41);
  --cpd-color-alpha-green-400: hsla(151, 93%, 37%, 0.23);
  --cpd-color-alpha-green-300: hsla(150, 100%, 36%, 0.11);
  --cpd-color-alpha-green-200: hsla(150, 79%, 41%, 0.06);
  --cpd-color-alpha-green-100: hsla(156, 79%, 41%, 0.03);
  */

  /* TODO accent variants? */
  --cpd-color-green-1400: #33691E;
  --cpd-color-green-1300: #33691E;
  --cpd-color-green-1200: #33691E;
  --cpd-color-green-1100: #33691E;
  --cpd-color-green-1000: #33691E;
  --cpd-color-green-900: #33691E;
  --cpd-color-green-800: #33691E;
  --cpd-color-green-700: #8BC34A;
  --cpd-color-green-600: #8BC34A;
  --cpd-color-green-500: #8BC34A;
  --cpd-color-green-400: #8BC34A;
  --cpd-color-green-300: #C5E1A5;
  --cpd-color-green-200: #DCEDC8;
  --cpd-color-green-100: #F1F8E9;

  /* TODO
  --cpd-color-red-1400: #450000;
  --cpd-color-red-1300: #620000;
  --cpd-color-red-1200: #850006;
  --cpd-color-red-1100: #a4041d;
  --cpd-color-red-1000: #bc0f22;
  --cpd-color-red-900: #d51928;
  --cpd-color-red-800: #ff3d3d;
  --cpd-color-red-700: #ff8c81;
  --cpd-color-red-600: #ffafa5;
  --cpd-color-red-500: #ffc5bc;
  --cpd-color-red-400: #ffdfda;
  --cpd-color-red-300: #ffefec;
  --cpd-color-red-200: #fff7f6;
  --cpd-color-red-100: #fffaf9;
  */

  --cpd-color-gray-1400: #212121;
  --cpd-color-gray-1300: #212121;
  --cpd-color-gray-1200: #424242;
  --cpd-color-gray-1100: #424242;
  --cpd-color-gray-1000: #616161;
  --cpd-color-gray-900: #616161;
  --cpd-color-gray-800: #808080;
  --cpd-color-gray-700: #b3b3b3;
  --cpd-color-gray-600: #b3b3b3;
  --cpd-color-gray-500: #cdd3da;
  --cpd-color-gray-400: #e0e0e0;
  --cpd-color-gray-300: #f5f5f5;
  --cpd-color-gray-200: #f5f5f5;
  --cpd-color-gray-100: #fafafa;
  --cpd-color-theme-bg: #ffffff;
  --cpd-color-tooltip-bg: #f5f5f5;
}
